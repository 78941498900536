export const tiracentos = (value) => {
  if (!value) return false;

  const validTypes = typeof value === "string"; 
  if (!validTypes) return false;
  
    const fordel = "áàâãäÁÀÂÃÄçÇéèêÉÈÊíìïÍÌÏóòöôõÓÒÖÔÕúùûüÚÙÛÜ";
    const forsub = "aaaaaAAAAAcCeeeEEEiiiIIIoooooOOOOOuuuuUUUU";
    let newname = "";
      for(let i = 0; i < value.length; i++){
      let nc = false;
      let oc = value.charAt(i);
      for(let j = 0; j < fordel.length; j++){
      let fd = fordel.charAt(j);
        if(oc == fd) nc = forsub.charAt(j); 
      }
      newname += nc ? nc : oc;
      }	 
      //alert(newname);
    return newname;
    
  
};
