import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { connect } from "react-redux";
import ReactTable from "react-table-v6";
import {
  fetchReports,
  selectedReport,
  fetchOrderServices,
  fetchUsers,
} from "../../actions";
import { tiracentos } from "../../util/tools/tiracentos";
import { Button, Grid } from "semantic-ui-react";

import Select from "react-select";
import "react-table-v6/react-table.css";

class DashboardListReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switches: [],
      selectedOption: 10,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.reports.length === 0) {
      this.props.fetchReports();
      this.props.fetchOrderServices();
      this.props.fetchUsers();
    }
  }

  handleChange(option) {
    this.setState({ selectedOption: option.value });
  }

  render() {
    const ptBRFormatter = new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const today = new Date();

    const dblock = {      
      display:'block',
      width:'200px',
      float:'left',
    };

    const floatLeft = { 
      float:'left',
      paddingRight: '2em'
    };

    const style200 = { 
      width:'200px',
    };

    const tenDays = {      
      marginBottom: '0.2em',
      backgroundColor:'#ffb84d',
      textAlign:'center',
      //float:'left',
      //border: '1px',
      //borderRadius: '4px',
      marginRight: '2em',
      padding:'0.5em',
      width:'80px',
      float:'left',
      //height:'0.8em',
      //fontSize: '10px',
    };


    const thirtyDays = {      
      marginBottom: '0.2em',
      backgroundColor:'#ffff4d',
      textAlign:'center',
      //border: '1px',
      //borderRadius: '4px',
      marginRight: '2em',
      padding:'0.5em',
      width:'80px',
      float:'left',
      //height:'0.8em',
      //fontSize: '10px',
    };

    var arrayAnteriores = [];

    const options = [
      { value: 10, label: "10 Dias" },
      { value: 20, label: "20 Dias" },
      { value: 30, label: "30 Dias" },
    ];

    const { reports } = this.props;

    const todayClean = new Date();
    // console.log("hoje", todayClean);
    const todayMoreChooseDays =
      todayClean.getDate() + this.state.selectedOption;
    // console.log(todayMoreChooseDays);
    const daysAhead = todayClean.setDate(todayMoreChooseDays);
    // const daysToday = todayClean.getDate();

    const listEndDate = _.filter(reports, { isArchived: false,  isDeleted: false}).filter(
      (report) => {
        return new Date(report.endDate).getTime() <= daysAhead;
      }
    );

    var j = 0;
        for (var i=0; i < this.props.reports.length; i++){
          if(this.props.reports[i].oldReport){
            arrayAnteriores[j] = {id:this.props.reports[i].oldReport.id};
            j++;
          }
        }

    return (
      <>
        <div className="ui grid">
          <div className="ten wide column">
            <h3>Laudos vencendo em 10 dias ou escolha ao lado</h3>
          </div>

          <div className="six wide column">
              <div style={floatLeft}>Vencendo em até: </div>
              <div style={tenDays}>10 dias</div>
              <div style={thirtyDays}>30 dias</div>
            <div>
              <Select
                calssName="ui mini"
                options={options}
                onChange={this.handleChange}
                defaultValue={this.state.selectedOption}
                placeholder={"Selecione dias..."}
              />
            </div>
          </div>
        </div>

        <div className="ui row mini">
          <div className={"sixteen wide column right bottom aligned content"}>
            <div className="ui label mini teal">
              <i className="thumbs up icon" />
              Aprovado
            </div>

            <div className="ui label mini red ">
              <i className="thumbs down icon" />
              Não aprovado
            </div>

            <div className="ui label mini green">
              <i className="folder open icon" />
              Ativo
            </div>
          </div>
        </div>
        <div className={"ui divider"} />
        <br />
        <ReactTable
          keyField="id"
          filterable
          showPagination={true}
          minRows={0}
          previousText="<"
          nextText=">"
          rowsText=""
          pageText="Página"
          ofText="de"
          noDataText="Nenhuma Informação"
          loadingText="Buscando dados..."
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) > -1
          }
          defaultPageSize={10}
          className="-striped -highlight"
          data={listEndDate}
          resolveData={(data) => data.map((row) => row)}
          columns={[
            {
              Header: "Empresa",
              accessor: "company.fantasyName",
              filterMethod: (filter, row) => {  
                let pattern = new RegExp(filter.value, 'gi');
                let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                              
                return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
              },
            },
            {
              Header: "Sigla",
              accessor: "company.initials",
              filterMethod: (filter, row) => {  
                let pattern = new RegExp(filter.value, 'gi');
                let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                              
                return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
              },
            },
            {
              Header: "Título",
              accessor: "title",
              maxWidth: 170,
              filterMethod: (filter, row) => {  
                let pattern = new RegExp(filter.value, 'gi');
                let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                              
                return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
              },
            },
            {
              Header: "Laudo Anterior",
              id: "oldReport",
              accessor: (d) =>{ 
                if(d.oldReport){
                  return d.oldReport.title;
                }               
              },
              maxWidth: 170,
              filterMethod: (filter, row) => {                 
                if(row[filter.id] != null){
                  let pattern = new RegExp(filter.value, 'gi');
                  let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                                
                  return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
                }else{          
                  return false;                  
                }
              },    
            },
            {
              Header: "Ordem de Serviço",
              id: "orderService",
              accessor: (d) =>{ 
                if(d.orderService){
                  return d.orderService.title;
                }               
              },
              maxWidth: 170,
              filterMethod: (filter, row) => {                 
                if(row[filter.id] != null){
                  let pattern = new RegExp(filter.value, 'gi');
                  let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                                
                  return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
                }else{          
                  return false;                  
                }
              },    
            },
            {
              Header: "Emissão",
              id: "startDate",
              maxWidth: 90,
              accessor: (d) => {
                return d.startDate;
              },
              Cell: (d) => {
                const custom_date = d.value;
                return <span>{ptBRFormatter.format(new Date(custom_date))}</span>;
              },
              filterMethod: (filter, row) => {
                if (filter.value.match(/\//)) {   
                  
                  let filtroArr = filter.value.split("/");

                  if(filtroArr.length == 3){
                    let pattern = new RegExp(filtroArr[2]  + "-" + filtroArr[1] + "-" + filtroArr[0]);
                    return row[filter.id].match(pattern);
                  }else if(filtroArr.length == 2){
                    if(filtroArr[1] == ""){
                      let pattern = new RegExp("-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 2){
                      let pattern = new RegExp("-" + filtroArr[1] + "-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 4){
                      let pattern = new RegExp(filtroArr[1] + "-" + filtroArr[0]+ "-");
                      return row[filter.id].match(pattern);
                    }
                  }
                }else{
                  if(filter.value.length == 2){
                    var pattern = new RegExp("-" + filter.value);
                  }else{
                    var pattern = new RegExp(filter.value);
                  }
                  
                  return row[filter.id].match(pattern);
                }
              },
            },
            {
              Header: "Validade",
              id: "endDate",
              maxWidth: 90,
              accessor: (d) => {
                return d.endDate;
              },
              Cell: (d) => {
                const custom_date = d.value;
                return <span>{ptBRFormatter.format(new Date(custom_date))}</span>;
              },
              filterMethod: (filter, row) => {
                if (filter.value.match(/\//)) {  

                  let filtroArr = filter.value.split("/");

                  if(filtroArr.length == 3){
                    let pattern = new RegExp(filtroArr[2]  + "-" + filtroArr[1] + "-" + filtroArr[0]);
                    return row[filter.id].match(pattern);
                  }else if(filtroArr.length == 2){
                    if(filtroArr[1] == ""){
                      let pattern = new RegExp("-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 2){
                      let pattern = new RegExp("-" + filtroArr[1] + "-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 4){
                      let pattern = new RegExp(filtroArr[1] + "-" + filtroArr[0]+ "-");
                      return row[filter.id].match(pattern);
                    }
                  }
                }else{
                  if(filter.value.length == 2){
                    var pattern = new RegExp("-" + filter.value);
                  }else{
                    var pattern = new RegExp(filter.value);
                  }
                  
                  return row[filter.id].match(pattern);
                }
              },
              getProps: (state, rowInfo) => ({
                style: {
                  backgroundColor:
                    today > new Date(rowInfo.original.endDate)
                      ? "#ffad99"
                      : 11 > (Math.round((new Date(rowInfo.original.endDate) - today) / (1000 * 60 * 60 * 24)))
                      ? "#ffb84d"
                      : 31 > (Math.round((new Date(rowInfo.original.endDate) - today) / (1000 * 60 * 60 * 24)))
                      ? "#ffff4d"
                      :null,
                },
              }),
            },
            {
              Header: "Tipo de Serviço",
              id: "services",
              accessor: (d) =>
                d.services.map((service) => {
                  return service.name;
                }),
              Cell: (props) =>
                props.original.services.map((service, index) => {
                  return (
                    <div key={index}>
                      <span className="ui label">{service.name}</span>
                      <br />
                    </div>
                  );
                }),
                filterMethod: (filter, row) => {  
                  let pattern = new RegExp(filter.value, 'gi');
                  let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                                
                  return (row[filter.id][0].match(pattern) || tiracentos(row[filter.id][0]).match(pattern2)); 
                },
            },
            {
              Header: "Tipo de Equipamento",
              id: "equipments",
              accessor: (d) =>
                d.equipments.map((equipment) => {
                  return equipment.name;
                }),
              Cell: (props) =>
                props.original.equipments.map((equipment, index) => {
                  return (
                    <div key={index}>
                      <span className="ui label">{equipment.name}</span>
                      <br />
                    </div>
                  );
                }),
                filterMethod: (filter, row) => {  
                  let pattern = new RegExp(filter.value, 'gi');
                  let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                                
                  return (row[filter.id][0].match(pattern) || tiracentos(row[filter.id][0]).match(pattern2)); 
                },
            },
            {
              Header: "Status",
              accessor: "isApproved",
              id: "isApproved",
              sortable: false,
              maxWidth: 80,
              Cell: ({ value }) =>
                value === true ? (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="teal"
                        size="mini"
                        icon="thumbs up"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                ) : (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="red"
                        size="mini"
                        icon="thumbs down"
                        // href={apiUrl + `/${e.file}`}
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                ),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row[filter.id] === true;
                }
                return row[filter.id] === false;
              },
              Filter: ({ filter, onChange }) => (
                <select
                  onChange={(event) => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                >
                  <option value="all">Todos</option>
                  <option value="true">Aprovado</option>
                  <option value="false">Não Aprovado</option>
                </select>
              ),
            },
            {
              Header: "Condição",
              accessor: "isNotSatisfactory",
              maxWidth: 85,
              sortable: false,
              id: "isNotSatisfactory",
              Cell: (props) =>
              props.original.isNotSatisfactory === true ? (

                  (_.find(arrayAnteriores, {id:props.original.id})) ? (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="purple"
                        size="mini"
                        icon="bug"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  ) : (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="red"
                        size="mini"
                        icon="bug"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  )
                ) : (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="green"
                        size="mini"
                        icon="shield alternate"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                ),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row[filter.id] === true;
                }
                if (filter.value === "false") {
                  return row[filter.id] === false;
                }
              },
              Filter: ({ filter, onChange }) => (
                <select
                  onChange={(event) => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                >
                  <option value="all">Todos</option>
                  <option value="false">Satisfatório</option>
                  <option value="true">Insatisfatório</option>
                </select>
              ),
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reports: _.orderBy(Object.values(state.reports), "endDate", "desc"),
    orderServices: _.orderBy(Object.values(state.orderServices), "id", "desc"),
    // users: Object.values(state.users),
    selectedReport: state.selectedReport,
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchReports,
    selectedReport,
    fetchOrderServices,
    fetchUsers,
  })(DashboardListReport)
);
