import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactTable from "react-table-v6";
import { fetchOrderServices, selectedOrderService } from "../../actions";
import { Button, Grid, Radio } from "semantic-ui-react";
import { tiracentos } from "../../util/tools/tiracentos";
import "react-table-v6/react-table.css";

class OrderServiceTableGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggle: false };
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentDidMount() {
    const { orderServices } = this.props;
    if (orderServices.length === 0) {
      this.props.fetchOrderServices();
    }
  }

  handleToggle(e) {
    e.preventDefault();

    this.setState({ isToggle: !this.state.isToggle });
  }

  render() {

    for(var u=0; u<this.props.orderServices.length; u++){
      var newArray = [];
      var newArray2 = [];
      var isObject = 0;
      var isObject2 = 0;

        if(!_.isArray(this.props.orderServices[u].services)){
          //  console.log('OBJECT');
          isObject = 1;
          var j = 0;
          for (var i in this.props.orderServices[u].services){
            if(this.props.orderServices[u].services[i] != ''){
              newArray[j] = this.props.orderServices[u].services[i];
              j++;
            }
          }
        }

        if(!_.isArray(this.props.orderServices[u].equipments)){
          //  console.log('OBJECT');
          isObject2 = 1;
          var j = 0;
          for (var i in this.props.orderServices[u].equipments){
            if(this.props.orderServices[u].equipments[i] != ''){
              newArray2[j] = this.props.orderServices[u].equipments[i];
              j++;
            }
          }
        }

        if(isObject){
          this.props.orderServices[u].services = newArray;
        }
        if(isObject2){
          this.props.orderServices[u].equipments = newArray2;
        }
      }

    const { currentUserIsAdmin } = this.props;

    const ptBRFormatter = new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const { orderServices } = this.props;

    const dataRender = this.state.isToggle
      ? _.filter(orderServices, { isArchived: true })
      : _.filter(orderServices, { isArchived: false });

    return (
      <>
        <div className="ui grid">
          <div className="ui row">
            <div className="ui sixteen wide column left aligned">
              <h1>Ordens de Serviço</h1>
            </div>

            <div className="ui twelve wide column left aligned">
              {/*<div className="ui label mini teal">*/}
              {/*  <i className="thumbs up icon" />*/}
              {/*  Aprovado*/}
              {/*</div>*/}
              {/*<div className="ui label mini red ">*/}
              {/*  <i className="thumbs down icon" />*/}
              {/*  Não aprovado*/}
              {/*</div>*/}
            </div>

            <div className="ui four wide column right aligned">
              <div className="item">
                <div className="ui label mini green">
                  <i className="folder open icon" />
                  Ativo
                </div>
                &nbsp; &nbsp;
                <Radio
                  toggle
                  type="checkbox"
                  onChange={this.handleToggle}
                  style={{ marginBottom: "-0.5rem" }}
                />
                &nbsp; &nbsp;
                <div className="ui label mini blue">
                  <i className="folder outline icon" />
                  Arquivado
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ui divider" />
        {currentUserIsAdmin ? (
          <Link
            className="ui small icon primary right floated right labeled button"
            to={"/order_service/new"}
          >
            <i aria-hidden="true" className="building icon" />
            Nova Ordem de Serviço
          </Link>
        ) : null}
        <br />
        <br />
        <br />
        <ReactTable
          keyField="id"
          filterable
          showPagination={true}
          minRows={0}
          previousText="<"
          nextText=">"
          rowsText=""
          pageText="Página"
          ofText="de"
          noDataText="Nenhuma Informação"
          loadingText="Buscando dados..."
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) > -1
          }
          defaultPageSize={10}
          className="-striped -highlight"
          data={dataRender}
          resolveData={(data) => data.map((row) => row)}
          columns={[
            {
              Header: "Empresa",
              accessor: "company.fantasyName",
              filterMethod: (filter, row) => {  
                let pattern = new RegExp(filter.value, 'gi');
                let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                              
                return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
              },
            },
            {
              Header: "Título",
              accessor: "title",
              maxWidth: 170,
              filterMethod: (filter, row) => {  
                let pattern = new RegExp(filter.value, 'gi');
                let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                              
                return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
              },
            },
            {
              Header: "Emissão",
              id: "startDate",
              maxWidth: 90,
              accessor: (d) => {
                return d.startDate;
              },
              Cell: (d) => {
                const custom_date = d.value;
                return <span>{ptBRFormatter.format(new Date(custom_date))}</span>;
              },
              filterMethod: (filter, row) => {
                if (filter.value.match(/\//)) {   
                  
                  let filtroArr = filter.value.split("/");

                  if(filtroArr.length == 3){
                    let pattern = new RegExp(filtroArr[2]  + "-" + filtroArr[1] + "-" + filtroArr[0]);
                    return row[filter.id].match(pattern);
                  }else if(filtroArr.length == 2){
                    if(filtroArr[1] == ""){
                      let pattern = new RegExp("-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 2){
                      let pattern = new RegExp("-" + filtroArr[1] + "-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 4){
                      let pattern = new RegExp(filtroArr[1] + "-" + filtroArr[0]+ "-");
                      return row[filter.id].match(pattern);
                    }
                  }
                }else{
                  if(filter.value.length == 2){
                    var pattern = new RegExp("-" + filter.value);
                  }else{
                    var pattern = new RegExp(filter.value);
                  }
                  
                  return row[filter.id].match(pattern);
                }
              },
            },
            {
              Header: "Tipo de Serviço",
              id: "services",
              accessor: (d) =>
                d.services.map((service) => {
                  return service.name;
                }),
              Cell: (props) =>
                props.original.services.map((service, index) => {
                  return (
                    <div key={index}>
                      <span className="ui label">{service.name}</span>
                      <br />
                    </div>
                  );
                }),
                filterMethod: (filter, row) => {  
                  let pattern = new RegExp(filter.value, 'gi');
                  let pattern2 = new RegExp(tiracentos(filter.value), 'gi'); 
                                
                  return (row[filter.id][0].match(pattern) || tiracentos(row[filter.id][0]).match(pattern2)); 
                }, 
            },
            {
              Header: "Tipo de Equipamento",
              id: "equipments",
              accessor: (d) =>
                d.equipments.map((equipment) => {
                  return equipment.name;
                }),
              Cell: (props) =>
                props.original.equipments.map((equipment, index) => {
                  return (
                    <div key={index}>
                      <span className="ui label">{equipment.name}</span>
                      <br />
                    </div>
                  );
                }),
                filterMethod: (filter, row) => {  
                  let pattern = new RegExp(filter.value, 'gi');
                  let pattern2 = new RegExp(tiracentos(filter.value), 'gi'); 
                                
                  return (row[filter.id][0].match(pattern) || tiracentos(row[filter.id][0]).match(pattern2)); 
                }, 
            },
            {
              Header: "Situação",
              accessor: "isArchived",
              filterable: false,
              maxWidth: 90,
              Cell: ({ value }) =>
                value === true ? (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="blue"
                        size="mini"
                        icon="folder outline"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                ) : (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="green"
                        size="mini"
                        icon="folder open"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                ),
            },
            {
              Header: "Ações",
              columns: [
                {
                  accessor: "id",
                  filterable: false,
                  maxWidth: 80,
                  Cell: (e) => (
                    <div className="ui column centered">
                      <Link
                        className="ui mini icon grey button"
                        to={`/order_service/show/${e.value}`}
                      >
                        <i aria-hidden="true" className="file icon" />
                      </Link>
                      {currentUserIsAdmin ? (
                        <Link
                          className="ui mini icon yellow button"
                          to={`/order_service/edit/${e.value}`}
                        >
                          <i aria-hidden="true" className="edit icon" />
                        </Link>
                      ) : null}
                    </div>
                  ),
                },
              ],
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUserIsAdmin: state.auth.isAdmin,
    orderServices: _.orderBy(
      Object.values(state.orderServices),
      "startDate",
      "desc"
    ),
    selectedOrderService: state.selectedOrderService,
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchOrderServices,
    selectedOrderService,
  })(OrderServiceTableGrid)
);
