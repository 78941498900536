import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactTable from "react-table-v6";
import { fetchReports, selectedReport, fetchUsers } from "../../actions";
import { Button, Grid, Radio } from "semantic-ui-react";
import { tiracentos } from "../../util/tools/tiracentos";

import "react-table-v6/react-table.css";

class ReportTableGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggle: false };
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentDidMount() {
    if (this.props.reports.length === 0) {
      this.props.fetchReports();
    }
  }

  handleToggle(e) {
    e.preventDefault();

    this.setState({ isToggle: !this.state.isToggle });
  }

  render() {

      for(var u=0; u<this.props.reports.length; u++){
        var newArray = [];
        var newArray2 = [];
        var isObject = 0;
        var isObject2 = 0;
        var arrayAnteriores = [];

        if(!_.isArray(this.props.reports[u].services)){
          //  console.log('OBJECT');
          isObject = 1;
          var j = 0;
          for (var i in this.props.reports[u].services){
            if(this.props.reports[u].services[i] != ''){
              newArray[j] = this.props.reports[u].services[i];
              j++;
            }
          }
        }

        if(!_.isArray(this.props.reports[u].equipments)){
          //  console.log('OBJECT');
          isObject2 = 1;
          var j = 0;
          for (var i in this.props.reports[u].equipments){
            if(this.props.reports[u].equipments[i] != ''){
              newArray2[j] = this.props.reports[u].equipments[i];
              j++;
            }
          }
        }

        if(isObject){
          this.props.reports[u].services = newArray;
        }
        if(isObject2){
          this.props.reports[u].equipments = newArray2;
        }

        var j = 0;
        for (var i=0; i < this.props.reports.length; i++){
          if(this.props.reports[i].oldReport){
            arrayAnteriores[j] = {id:this.props.reports[i].oldReport.id};
            j++;
          }
        }

      }

    const { currentUserIsAdmin } = this.props;

    const ptBRFormatter = new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const today = new Date();
    const { reports } = this.props;    

    const styleDel = {
      color: 'red',  
      fontWeight: 'bold',  
      width: '8em',
      marginLeft: '0.2em',
      backgroundColor:'#F9F463',
      border: '1px',
      borderRadius: '4px',
      padding: '0.5em',
      height:'0.8em',
      fontSize: '10px',
    };

    const dataRender = this.state.isToggle
      ? _.filter(reports, { isArchived: true })
      : _.filter(reports, { isArchived: false }); 

      var f = 0;
      var dataRenderFinal = [];

      for (var d=0; d < dataRender.length; d++){
        if(!dataRender[d].isDeleted || (dataRender[d].isDeleted && dataRender[d].isReleased)){
          dataRenderFinal[f] = dataRender[d];
          f++;
        }
      }

    return (
      <>
        <div className="ui grid">
          <div className="ui row">
            <div className="ui sixteen wide column left aligned">
              <h1>Laudos</h1>
            </div>

            <div className="ui twelve wide column left aligned">
              <div className="ui label mini teal">
                <i className="thumbs up icon" />
                Assinado
              </div>
              <div className="ui label mini red ">
                <i className="thumbs down icon" />
                Não assinado
              </div>
              <span style={styleDel}> 
                <span>D- Deletado</span>
              </span>
            </div>

            <div className="ui four wide column right aligned">
              <div className="item">
                <div className="ui label mini green">
                  <i className="folder open icon" />
                  Ativo
                </div>
                &nbsp; &nbsp;
                <Radio
                  toggle
                  type="checkbox"
                  onChange={this.handleToggle}
                  style={{ marginBottom: "-0.5rem" }}
                />
                &nbsp; &nbsp;
                <div className="ui label mini blue">
                  <i className="folder outline icon" />
                  Arquivado
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ui divider" />
     {/*   {currentUserIsAdmin ? ( */}
          <Link
            className="ui small icon primary right floated right labeled button"
            to={"/report/new"}
          >
            <i aria-hidden="true" className="building icon" />
            Novo Laudo
          </Link>
      {/*  ) : null} */}
        <br />
        <br />
        <br />
        <ReactTable
          keyField="id"
          filterable
          showPagination={true}
          minRows={0}
          previousText="<"
          nextText=">"
          rowsText=""
          pageText="Página"
          ofText="de"
          noDataText="Nenhuma Informação"
          loadingText="Buscando dados..."
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) > -1
          }
          defaultPageSize={10}
          className="-striped -highlight"
          data={dataRenderFinal}
          resolveData={(data) => data.map((row) => row)}
          columns={[
            {
              Header: "Empresa",
              id: "company",
              accessor: (d) =>{                
                  return d.company.fantasyName;                
              },
              filterMethod: (filter, row) => {  
                let pattern = new RegExp(filter.value, 'gi');
                let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                              
                return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
              }, 
            },
            {
              Header: "Sigla",
              accessor: "company.initials",
              filterMethod: (filter, row) => {  
                let pattern = new RegExp(filter.value, 'gi');
                let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                              
                return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
              },
            },
            {
              Header: "Título do Laudo",
              id: "title",
              accessor: (d) => {
                return d.title;
              },
              maxWidth: 170,
              Cell: (d) => {
                if(d.original.isDeleted){
                  const tituloDel = "D- ";
                  const titulo = d.value;
                  return <span><span style={{color:'red', fontWeight:'bold'}} >{tituloDel}</span>{titulo}</span>;
                }else{
                  const titulo = d.value;
                  return <span>{titulo}</span>;
                }                
              },
              filterMethod: (filter, row) => {  
                let pattern = new RegExp(filter.value, 'gi');
                let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                              
                return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
              },
            },
            {
              Header: "Laudo Anterior",
              id: "oldReport",
              accessor: (d) =>{ 
                if(d.oldReport){
                  return d.oldReport.title;
                }               
              },
              maxWidth: 170,
              filterMethod: (filter, row) => {  
                if(row[filter.id] != null){
                  let pattern = new RegExp(filter.value, 'gi');
                  let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                                
                  return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
                }else{          
                  return false;                  
                }
              },               
            },
            {
              Header: "Ordem de Serviço",             
              id: "orderService",
              accessor: (d) =>{ 
                if(d.orderService){
                  return d.orderService.title;
                }               
              },
              maxWidth: 170,
              filterMethod: (filter, row) => {                 
                if(row[filter.id] != null){
                  let pattern = new RegExp(filter.value, 'gi');
                  let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                                
                  return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
                }else{          
                  return false;                  
                }
              },    
            }, 
            {
              Header: "Emissão",
              id: "startDate",
              maxWidth: 90,
              accessor: (d) => {
                return d.startDate;
              },
              Cell: (d) => {
                const custom_date = d.value;
                return <span>{ptBRFormatter.format(new Date(custom_date))}</span>;
              },
              filterMethod: (filter, row) => {
                if (filter.value.match(/\//)) {   
                  
                  let filtroArr = filter.value.split("/");

                  if(filtroArr.length == 3){
                    let pattern = new RegExp(filtroArr[2]  + "-" + filtroArr[1] + "-" + filtroArr[0]);
                    return row[filter.id].match(pattern);
                  }else if(filtroArr.length == 2){
                    if(filtroArr[1] == ""){
                      let pattern = new RegExp("-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 2){
                      let pattern = new RegExp("-" + filtroArr[1] + "-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 4){
                      let pattern = new RegExp(filtroArr[1] + "-" + filtroArr[0]+ "-");
                      return row[filter.id].match(pattern);
                    }
                  }
                }else{
                  if(filter.value.length == 2){
                    var pattern = new RegExp("-" + filter.value);
                  }else{
                    var pattern = new RegExp(filter.value);
                  }
                  
                  return row[filter.id].match(pattern);
                }
              },
            },
            {
              Header: "Validade",
              id: "endDate",
              maxWidth: 90,
              accessor: (d) => {
                return d.endDate;
              },
              Cell: (d) => {
                const custom_date = d.value;
                return <span>{ptBRFormatter.format(new Date(custom_date))}</span>;
              },
              filterMethod: (filter, row) => {
                if (filter.value.match(/\//)) { 
                    
                  let filtroArr = filter.value.split("/");

                  if(filtroArr.length == 3){
                    let pattern = new RegExp(filtroArr[2]  + "-" + filtroArr[1] + "-" + filtroArr[0]);
                    return row[filter.id].match(pattern);
                  }else if(filtroArr.length == 2){
                    if(filtroArr[1] == ""){
                      let pattern = new RegExp("-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 2){
                      let pattern = new RegExp("-" + filtroArr[1] + "-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 4){
                      let pattern = new RegExp(filtroArr[1] + "-" + filtroArr[0]+ "-");
                      return row[filter.id].match(pattern);
                    }
                  }
                }else{
                  if(filter.value.length == 2){
                    var pattern = new RegExp("-" + filter.value);
                  }else{
                    var pattern = new RegExp(filter.value);
                  }
                  
                  return row[filter.id].match(pattern);
                }
              },
              getProps: (state, rowInfo) => ({
                style: {
                  backgroundColor:
                  today > new Date(rowInfo.original.endDate)
                  ? "#ffad99"
                  : 11 > (Math.round((new Date(rowInfo.original.endDate) - today) / (1000 * 60 * 60 * 24)))
                  ? "#ffb84d"
                  : 31 > (Math.round((new Date(rowInfo.original.endDate) - today) / (1000 * 60 * 60 * 24)))
                  ? "#ffff4d"
                  :null,
                },
              }),
            },
            {
              Header: "Tipo de Serviço",
              id: "services",
              accessor: (d) =>
                d.services.map((service) => {
                  return service.name;
                }),
              Cell: (props) =>
                props.original.services.map((service, index) => {
                  return (
                    <div key={index}>
                      <span className="ui label">{service.name}</span>
                      <br />
                    </div>
                  );
                }),
                filterMethod: (filter, row) => {  
                  let pattern = new RegExp(filter.value, 'gi');
                  let pattern2 = new RegExp(tiracentos(filter.value), 'gi'); 
                                
                  return (row[filter.id][0].match(pattern) || tiracentos(row[filter.id][0]).match(pattern2)); 
                }, 
            },
            {
              Header: "Tipo de Equipamento",
              id: "equipments",
              accessor: (d) =>
                d.equipments.map((equipment) => {
                  return equipment.name;
                }),
              Cell: (props) =>
                props.original.equipments.map((equipment, index) => {
                  return (
                    <div key={index}>
                      <span className="ui label">{equipment.name}</span>
                      <br />
                    </div>
                  );
            }), 
              filterMethod: (filter, row) => {  
                let pattern = new RegExp(filter.value, 'gi');
                let pattern2 = new RegExp(tiracentos(filter.value), 'gi'); 
                              
                return (row[filter.id][0].match(pattern) || tiracentos(row[filter.id][0]).match(pattern2)); 
              }, 
            },
            {
              Header: "Status",
              accessor: "isApproved",
              maxWidth: 90,
              sortable: false,
              id: "isApproved",
              Cell: (props) =>
                props.value === true ? (
                  props.original.isDeleted ? (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        //color="teal"
                        size="mini"
                        icon="thumbs up"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  ) : (
                    <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="teal"
                        size="mini"
                        icon="thumbs up"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  )
                ) : (
                  props.original.isDeleted ? (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        //scolor="red"
                        size="mini"
                        icon="thumbs down"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  ) : (
                    <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="red"
                        size="mini"
                        icon="thumbs down"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  )
                ),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row[filter.id] === true;
                }
                if (filter.value === "false") {
                  return row[filter.id] === false;
                }
              },
              Filter: ({ filter, onChange }) => (
                <select
                  onChange={(event) => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                >
                  <option value="all">Todos</option>
                  <option value="true">Assinado</option>
                  <option value="false">Não Assinado</option>
                </select>
              ),
            },
            {
              Header: "Condição",
              accessor: "isNotSatisfactory",
              maxWidth: 85,
              sortable: false,
              id: "isNotSatisfactory",
              Cell: (props) =>
                props.original.isNotSatisfactory === true ? (

                  (_.find(arrayAnteriores, {id:props.original.id})) ? (
                    props.original.isDeleted ? ( 
                    <Grid>
                      <Grid.Column textAlign="center">
                        <Button
                          as="a"
                          //color="olive"
                          size="mini"
                          icon="bug"
                          target="_blank"
                          rel="noreferrer"
                        />
                      </Grid.Column>
                    </Grid>
                    ) : (
                      <Grid>
                      <Grid.Column textAlign="center">
                        <Button
                          as="a"
                          //color="olive"
                          size="mini"
                          icon="bug"
                          target="_blank"
                          rel="noreferrer"
                        />
                      </Grid.Column>
                    </Grid>
                    )
                  ) : (
                    props.original.isDeleted ? (
                    <Grid>
                      <Grid.Column textAlign="center">
                        <Button
                          as="a"
                          //color="olive"
                          size="mini"
                          icon="bug"
                          target="_blank"
                          rel="noreferrer"
                        />
                      </Grid.Column>
                    </Grid>
                    ) : (
                      <Grid>
                      <Grid.Column textAlign="center">
                        <Button
                          as="a"
                          color="red"
                          size="mini"
                          icon="bug"
                          target="_blank"
                          rel="noreferrer"
                        />
                      </Grid.Column>
                    </Grid>
                    )
                  )
                ) : (
                  props.original.isDeleted ? (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        //color="olive"
                        size="mini"
                        icon="shield alternate"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  ) : (
                    <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="green"
                        size="mini"
                        icon="shield alternate"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  )
                ),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row[filter.id] === true;
                }
                if (filter.value === "false") {
                  return row[filter.id] === false;
                }
              },
              Filter: ({ filter, onChange }) => (
                <select
                  onChange={(event) => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                >
                  <option value="all">Todos</option>
                  <option value="false">Satisfatório</option>
                  <option value="true">Insatisfatório</option>
                </select>
              ),
            },
            {
              Header: "Situação",
              accessor: "isArchived",
              filterable: false,
              sortable: false,
              maxWidth: 65,
              Cell: (props) =>
                props.value === true ? (
                  props.original.isDeleted ? (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        //color="blue"
                        size="mini"
                        icon="folder outline"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  ) : (
                    <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="blue"
                        size="mini"
                        icon="folder outline"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  )
                ) : (
                  props.original.isDeleted ? (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        //color="green"
                        size="mini"
                        icon="folder open outline"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  ) : (
                    <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="green"
                        size="mini"
                        icon="folder open"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  )
                ),
            },
            {
              Header: "Liberado",
              accessor: "isReleased",
              maxWidth: 85,
              sortable: false,
              id: "isReleased",
              Cell: (props) =>
                props.value === true ? (
                  props.original.isDeleted ? (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        //color="olive"
                        size="mini"
                        icon="arrow up"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  ) : (
                    <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="green"
                        size="mini"
                        icon="arrow up"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>

                  )
                ) : (
                  props.original.isDeleted ? (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        //color="olive"
                        size="mini"
                        icon="arrow down"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  ) : (
                    <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="red"
                        size="mini"
                        icon="arrow down"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  )
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id] === true;
                  }
                  if (filter.value === "false") {
                    return row[filter.id] === false;
                  }
                },
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">Todos</option>
                    <option value="true">Liberado</option>
                    <option value="false">Não Liberado</option>
                  </select>
                ),
            },
            {
              Header: "Ações",
              columns: [
                {
                  accessor: "id",
                  filterable: false,
                  maxWidth: 80,
                  sortable: false,
                  Cell: (props) => (
                    <div className="ui column centered">
                    {/* Se descomentar nao visualiza quando laudo estiver deletado 
                    
                    {props.original.isDeleted ? (
                      <p
                        className="ui mini icon  button"
                      >
                        <i aria-hidden="true" className="eye slash outline icon" />
                      </p>
                      ) : ( */} <Link
                        className="ui mini icon grey button"
                        to={`/report/show/${props.original.id}`}
                      >
                        <i aria-hidden="true" className="file icon" />
                      </Link>
                    {/*  {currentUserIsAdmin ? ( */}
                       { props.original.isDeleted ? (
                        <p
                          className="ui mini icon  button"
                        >
                          <i aria-hidden="true" className="edit outline icon" />
                        </p>
                        ) : (
                          <Link
                          className="ui mini icon yellow button"
                          to={`/report/edit/${props.original.id}`}
                        >
                          <i aria-hidden="true" className="edit icon" />
                        </Link>
                        )}
                    {/*  ) : null} */}
                    </div>
                  ),
                },
              ],
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUserIsAdmin: state.auth.isAdmin,
    reports: _.orderBy(Object.values(state.reports), "startDate", "desc"),
    selectedReport: state.selectedReport,
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchReports,
    selectedReport,
  })(ReportTableGrid)
);
