import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import requireAuth from "../auth/requireAuth";
import ReactTable from "react-table-v6";
import { fetchContas, selectedConta } from "../../actions";
import { tiracentos } from "../../util/tools/tiracentos";

import "react-table-v6/react-table.css";

class ContaTableGrid extends React.Component {
  componentDidMount() {
    if (this.props.contas.length === 0) {
      this.props.fetchContas();
    }
  }

  render() {
    const { currentUserIsAdmin } = this.props;

    const onRowClick = (state, rowInfo) => {
      return {
        onClick: (e) => {
          this.props.selectedConta(rowInfo.original);
        },
      };
    };

    return (
      <>
        <div className="twelve wide column left aligned">
          <h1>Grupos Empresariais</h1>
        </div>
        <div className="ui divider" />
        {currentUserIsAdmin ? (
          <Link
            className="ui small icon primary right floated right labeled button"
            to={"/conta/new"}
          >
            <i aria-hidden="true" className="building icon" />
            Novo Grupo Empresarial
          </Link>
        ) : null}

        <br />
        <br />
        <br />
        <ReactTable
          keyField="id"
          getTdProps={onRowClick}
          filterable
          showPagination={true}
          minRows={0}
          previousText="<"
          nextText=">"
          rowsText=""
          pageText="Página"
          ofText="de"
          noDataText="Nenhuma Informação"
          loadingText="Buscando dados..."
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) > -1
          }
          defaultPageSize={10}
          className="-striped -highlight"
          data={this.props.contas}
          resolveData={(data) => data.map((row) => row)}
          columns={[
            {
              Header: "Grupo Empresarial",
              accessor: "fantasyName",
              filterMethod: (filter, row) => {  
                let pattern = new RegExp(filter.value, 'gi');
                let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                              
                return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2));  
              },
            },

            {
              Header: "Ações",
              columns: [
                {
                  accessor: "id",
                  filterable: false,
                  maxWidth: 80,
                  Cell: (e) => (
                    <div className="ui column centered">
                      <Link
                        className="ui mini icon grey button"
                        to={`/conta/show/${e.value}`}
                      >
                        <i aria-hidden="true" className="file icon" />
                      </Link>
                      {currentUserIsAdmin ? (
                        <Link
                          className="ui mini icon yellow button"
                          to={`/conta/edit/${e.value}`}
                        >
                          <i aria-hidden="true" className="edit icon" />
                        </Link>
                      ) : null}
                    </div>
                  ),
                },
              ],
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUserIsAdmin: state.auth.isAdmin,
    contas: _.orderBy(Object.values(state.contas), "fantasyName", "asc"),
    selectedConta: state.selectedConta,
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchContas,
    selectedConta,
  })(ContaTableGrid)
);
