import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactTable from "react-table-v6";
import { tiracentos } from "../../util/tools/tiracentos";
import {
  fetchUsers,
  selectedUser,
  fetchContas,
  fetchCompanies,
} from "../../actions";

import "react-table-v6/react-table.css";

class UserTableGrid extends React.Component {
  componentDidMount() {
    if (this.props.users.length === 0) {
      this.props.fetchUsers();
    }
    this.props.fetchContas();
    this.props.fetchCompanies();
    // window.location.reload();
  }

  render() {

    for(var u=0; u<this.props.users.length; u++){
      var newArray = [];
      var isObject = 0;

        if(!_.isArray(this.props.users[u].companies)){
          //  console.log('OBJECT');
          isObject = 1;
          var j = 0;
          for (var i in this.props.users[u].companies){
            if(this.props.users[u].companies[i] != ''){
              newArray[j] = this.props.users[u].companies[i];
              j++;
            }
          }
        }

        if(isObject){
          this.props.users[u].companies = newArray;
        }
      }

    const { currentUserIsAdmin } = this.props;
    //console.log("PROPS_USERS", this.props.users);

    const onRowClick = (state, rowInfo) => {
      return {
        onClick: (e) => {
          this.props.selectedUser(rowInfo.original);
        },
      };
    };

    return (
      <>
        <div className={"twelve wide column left aligned"}>
          <h1>Usuários</h1>
        </div>
        <div className={"ui divider"} />
        {currentUserIsAdmin ? (
          <Link
            className="ui small icon primary right floated right labeled button"
            to={"/user/new"}
          >
            <i aria-hidden="true" className="building icon" />
            Novo Usuário
          </Link>
        ) : null}
        <br />
        <br />
        <br />
        <ReactTable
          keyField="id"
          getTdProps={onRowClick}
          filterable
          showPagination={true}
          minRows={0}
          previousText="<"
          nextText=">"
          rowsText=""
          pageText="Página"
          ofText="de"
          noDataText="Nenhuma Informação"
          loadingText="Buscando dados..."
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) > -1
          }
          defaultPageSize={10}
          className="-striped -highlight"
          data={this.props.users}
          resolveData={(data) => data.map((row) => row)}
          columns={[
            {
              Header: "Grupo Empresarial",
              accessor: "conta.fantasyName",
              filterMethod: (filter, row) => {  
                let pattern = new RegExp(filter.value, 'gi');
                let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                              
                return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
              },
            },
            {
              Header: "Empresas",
              id: "companies",
              accessor: (d) =>
                d.companies.map((company) => {
                  return company.fantasyName;
                }),
              Cell: (props) =>
                props.original.companies.map((company, index) => {
                  return (
                    <div key={index}>
                      <span className="ui label">{company.fantasyName}</span>
                      <br />
                    </div>
                  );
                }),
                filterMethod: (filter, row) => { 
                 
                  let pattern = new RegExp(filter.value, 'gi');
                  let pattern2 = new RegExp(tiracentos(filter.value), 'gi');   

                  let forRet = new Array();
                  
                  row[filter.id].map((companyfilt) => {                   
                    if(companyfilt){                      
                      if(companyfilt.match(pattern) || tiracentos(companyfilt).match(pattern2)){                        
                        forRet.push(companyfilt);
                      }else{
                        return false;
                      }  
                    } 
                  })

                  if(forRet.length > 0){  
                    return forRet;
                  }
                },
            },
            {
              Header: "Nome",
              accessor: "name",
              filterMethod: (filter, row) => {  
                let pattern = new RegExp(filter.value, 'gi');
                let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                              
                return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
              },
            },
            {
              Header: "Função",
              accessor: "function",
              maxWidth: 100,
              filterMethod: (filter, row) => {  
                let pattern = new RegExp(filter.value, 'gi');
                let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                              
                return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
              },
            },
            {
              Header: "E-mail",
              accessor: "email",
              maxWidth: 140,
            },
            {
              Header: "Login",
              accessor: "username",
              maxWidth: 120,
            },
            {
              Header: "Ações",
              columns: [
                {
                  accessor: "id",
                  filterable: false,
                  maxWidth: 80,
                  Cell: (e) => (
                    <div className="ui column centered">
                      <Link
                        className="ui mini icon grey button"
                        to={`/user/show/${e.value}`}
                      >
                        <i aria-hidden="true" className="file icon" />
                      </Link>
                      {currentUserIsAdmin ? (
                        <Link
                          className="ui mini icon yellow button"
                          to={`/user/edit/${e.value}`}
                        >
                          <i aria-hidden="true" className="edit icon" />
                        </Link>
                      ) : null}
                    </div>
                  ),
                },
              ],
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUserIsAdmin: state.auth.isAdmin,
    selectedUser: state.selectedUser,
    users: _.reject(_.orderBy(Object.values(state.users), "id", "desc"), {
      profile: "/api/profiles/1",
    }),
    contas: Object.values(state.contas),
    companies: Object.values(state.companies),
  };
};

export default requireAuth(
  requireAuth(
    connect(mapStateToProps, {
      selectedUser,
      fetchUsers,
      fetchContas,
      fetchCompanies,
    })(UserTableGrid)
  )
);
