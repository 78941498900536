import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Dropdown, Icon } from "semantic-ui-react";

import logo from "../logo_g7spr.svg";

class Header extends React.Component {
  componentDidMount() {
    // const userId = window.localStorage.getItem("userId");
    // const { userSetId } = this.props;
    // if (userId) {
    //   userSetId(userId);
    // }
    // this.props.fetchUsers();
  }

  renderUserInfo() {
    const { userData, currentUserId } = this.props;

    if (!userData) {
      return <div className="ui active centered inline loader" />;
    }
  }

  renderLinks() {
    const { isAuthenticated, currentUserIsAdmin } = this.props;

    if (isAuthenticated) {
      return (
        <div className="right menu">
          <Link className="item" to="/dashboard">
            DashBoard
          </Link>
          <Link className="item" to="/conta/list">
            Grupos Empresariais
          </Link>
          <Link className="item" to="/company/list">
            Empresas
          </Link>
          <Link className="item" to="/user/list">
            Usuários
          </Link>
          <Link className="item" to="/order_service/list">
            Ordens de Serviço
          </Link>
          <Link className="item" to="/report/list">
            Laudos
          </Link>
          <Link className="item" to="/contract/list">
            Contratos
          </Link>
          {currentUserIsAdmin ? (
            <Dropdown item text="Admin">
              <Dropdown.Menu>
                <Dropdown.Item>
                  <Link className="item" to="/company_user/list">
                    Usuário G7
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : null}
          <Dropdown item text="Meu Perfil">
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link className={"item"} to={"/account/show"}>
                  Info
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link className={"item"} to={"/logout"}>
                  Sair
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return (
        <div className={"right menu"}>
          <Link className={"item"} to={"/"}>
            Home
          </Link>
        </div>
      );
    }
  }

  render() {
    return (
      <div className={"ui secondary pointing menu grid"}>
        <Link className={"item"} to={"/"}>
          <img src={logo} alt={"g7 serviços de proteção radiológico"} />
        </Link>
        {this.renderLinks()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.auth,
    userData: Object.values(state.users),
    currentUserId: state.auth.userId,
    currentUserIsAdmin: state.auth.isAdmin,
  };
}

export default connect(mapStateToProps)(Header);
